import { Link, Outlet } from "react-router-dom";
import { ProfileCard } from "components/user";
import { ReactComponent as Logo } from "assets/icons/logo.svg";

export const App = () => {
  return (
    <div className="h-screen">
      <header className="flex flex-wrap justify-between items-end px-4 py-3">
        <Link to={"/"} className="outline-blue-500">
          <Logo className="flex-shrink-" />
        </Link>
        <ProfileCard />
      </header>
      <main style={{ height: "calc(100vh - 66px)" }}>
        <Outlet />
      </main>
    </div>
  );
};
