import { FC } from "react";
import { ButtonProps } from "./button-props.interface";
import { ReactComponent as LoaderIcon } from "assets/icons/loader-icon.svg";
import { strSpaceCombine } from "utils/helpers";

export const Button: FC<ButtonProps> = ({
  type = "button",
  className,
  containerClassName,
  label,
  loading,
  ...props
}) => {
  return (
    <button
      className={strSpaceCombine([
        "bg-blue-500 py-3 px-4 mt-4 rounded-md outline-none focus:ring-8 focus:ring-blue-500/30 transition-all",
        `${loading && "cursor-not-allowed grayscale"}`,
        containerClassName,
      ])}
      type={type}
      disabled={loading}
      {...props}
    >
      {loading ? <LoaderIcon className="animate-spin mx-auto" /> : label}
    </button>
  );
};
