export const strSpaceCombine = (array: Array<any>) => array.filter(Boolean).join(" ");

export const getLocaleDateTime = (date?: string) => {
  const dateObj = date ? new Date(date) : new Date();

  return {
    date: dateObj.toLocaleDateString(),
    time: dateObj.toLocaleTimeString(),
  };
};
